const FACILITIES = [
  {
    title: "LIME RESORT HAKONE",
    location: "箱 根",
    description:
      "ダミーテキスト「時間＝TIME」は人間が算出した世界標準の単位です。しかし私たちは「ひとりの人生に与えられた、限りある時間」という、もうひとつの時間の流れの中でも生きています。 ナビタイムジャパンは、これから情報産業の核となる「パーソナル＝個人」の時間に焦点をあて、移動する",
    image: "/facilities/new/1.png",
    type_en: "Hotel",
    type_jp: "ホテル",
  },
  {
    title: "ロッテアライリゾート",
    location: "新 潟",
    description:
      "東京から新幹線で1時間46分、新潟空港から車で2時間に位置する新潟県妙高市。妙高山から海まで続く山々と、遥かに広がる田園風景が目を楽しませてくれる風光明媚なところに土地にロッテアライリゾートはございます。ロッテアライリゾートは全257室で長期滞在のお客様にも対応しております。",
    image: "/facilities/new/2.png",
    type_en: "Resort",
    type_jp: "リゾート",
  },
  {
    title: "SOKI ATAMI",
    location: "熱 海",
    description:
      "東京から新幹線で1時間46分、新潟空港から車で2時間に位置する新潟県妙高市。妙高山から海まで続く山々と、遥かに広がる田園風景が目を楽しませてくれる風光明媚なところに土地にロッテアライリゾートはございます。ロッテアライリゾートは全257室で長期滞在のお客様にも対応しております。",
    image: "/facilities/new/3.png",
    type_en: "Onsen",
    type_jp: "温 泉",
  },
  {
    title: "アロフト東京銀座",
    location: "銀 座",
    description:
      "新世代のライフスタイルホテル「アロフト東京銀座」は、 テクノロジーとデザインで人々をつなげ、あなたの可能性を広げます。無料高速WI-FIはもちろん、遊び心に満ちたオープンスペースでのワーケーションはいかがでしょうか。館内には2箇所のバーとダイニング。ロビースペースにあるz",
    image: "/facilities/new/4.png",
    type_en: "City",
    type_jp: "都 心",
  },
  {
    title: "BREEZE",
    location: "千 葉",
    description:
      "BREEZEではテント以外にもテーブル・椅子・寝袋・ランタン・調理器具や食器など、最低限必要な備品が全て用意されており個別にレンタルする必要はありません。場内はWi-Fiが使用可能である他、各サイトには電源もありワーケーションにもご利用頂けます。",
    image: "/facilities/new/5.png",
    type_en: "Outdoor",
    type_jp: "アウトドア",
  },
  {
    title: "正暦寺",
    location: "京 都",
    description:
      "1000年を超える歴史を持つ「正暦寺」は京都・綾部の豊かな自然に囲まれたお寺。夜には大粒の星が空に輝きます。宿坊となる客殿は築100年を超える純和室で、宿泊できるのは1日1組限定。3つ繋がった8畳の和室は壁一面が窓になっているので眺望がよく、襖で仕切れるのでグループでの滞在におす",
    image: "/facilities/new/6.png",
    type_en: "Shukubo",
    type_jp: "宿 坊",
  },
];

export { FACILITIES };
