import { API, i18n } from "@project/shared";

export const GetContentsFromID = async (): Promise<any> => {
  const refactoredData = [];
  const contentData = await API.get(`/user/home`);

  contentData?.data?.map((item) =>
    refactoredData.push({
      type: "one",
      data: {
        key: item?.id,
        imageSrc: item?.coverImage,
        feature: item?.genre,
        title: item?.contentTitle,
        subTitle: `${item?.prefecture && i18n.t(item?.prefecture)}  ${
          item?.scopeOfAccommodations &&
          (item?.prefecture
            ? "|" + item?.scopeOfAccommodations
            : item?.scopeOfAccommodations)
        }  ${
          item?.acceptableNumberOfPeople &&
          (item?.scopeOfAccommodations
            ? "|" + item?.acceptableNumberOfPeople
            : item?.acceptableNumberOfPeople)
        }`,
        location: item?.name,
      },
    })
  );

  return refactoredData;
};

export const GetUserContentsByID = async (id: any): Promise<any> => {
  return API.get(`/user/content/${id}`);
};

export const getFilteredContents = async ({ category }: any, page: number) => {
  return API.get(`/user/home`, {
    params: {
      content: category,
      pageSize: 12,
      page: page,
    },
  });
};

export const GetFacilityDetails = async (id: any): Promise<any> => {
  return API.get(`/user/facility/${id}`);
};
