const COACHING_DATA = [
  {
    image: "/contents/coaching/1.png",
    title: "2大テーマパーク流人財育成メソッド 成果を創るリーダー育成研修",
    facility: "ワンダーイマジニア",
    content:
      "2018年日本の人事部主催HRアワードにノミネートされた「ディズニー＆USJで学んだ現場を強くするリーダーの原理原則」の書籍から、多くのチームメンバーの本気を引き出しリーダーとして必要な「物事の見方、考え方、捉え方」を軸に高い成果をチーム単位で創り出す為に必要な行動の起点となるリーダーの特性を学びます。",
  },
  {
    image: "/contents/coaching/2.png",
    title: "成果を創り出す「コミュニケーション」と「場」創りプログラム",
    facility: "ワンダーイマジニア",
    content:
      "場づくりコンセプトは「デザイン インテント」「ストーリー」「ロール」「プロアクティブコミュニケーション」「質の高い問い」。私たちらしい「場」を発見し、それを浸透させる質の高い「コミュニケーション」を構造から学びます。。",
  },
  {
    image: "/contents/coaching/3.png",
    title: "Whyからチームが動き始める〜チームアクティベートワークショップ",
    facility: "HackCamp",
    content:
      "ありたい未来から考える「バックキャスト思考」を実践できるワークショップを行います。ありたい未来をチーム全員で自分ごと化することは、チームの持続的で自律的な変化に繋がります。まさに、「明日から組織が変わる」きっかけとなる、「チームアクティベート」なオフサイトミーティングとなります。",
  },
  {
    image: "/contents/coaching/4.png",
    title: "「レジリエンス（しなやかな回復力）」を体感するチームオフサイト",
    facility: "BE AUTHENTIC",
    content:
      "アスリートの世界でも、ビジネスの世界でも必要とされるメンタル。どんな事が起きても動じない心でなく、どんな事が起きてもすぐに自分のニュートラルな状態に回復できるしなやかに強い心。そんな「レジリエンス（しなやかな回復力）」をテーマにしています。体と繋がること、心と繋がることで自分の弱さや脆さも受け入れ、本当の強さを身につけ、今持っている力を最大限に発揮できる心の強さを育みます。",
  },
  {
    image: "/contents/coaching/5.png",
    title: "料理をツールに、効果的で、新しいチームビルディングのカタチ",
    facility: "Sorisso 料理DEチームビルディング　Be Design",
    content:
      "例えば、料理5品を同時に調理、同じ時間に仕上げるには、段取りを組む必要があり、複数の調理工程を同時進行する必要があります。例えば、チームミッションを、レストランの様な仕上がりの料理「チーム・プロジェクト」と設定し、チームでプロジェクトに取り組みます。調理工程をタスクに、仕上がり時間を逆算して段取りを組むことをタイムマネジメントに、チーム内で役割分担をして、料理プロジェクトをビジネスに必要なスキルに置き換え、チーム力を高めていきます。",
  },
];

const ACTIVITY_DATA = [
  {
    image: "/contents/activity/1.png",
    title: "学校跡地で大人のチームビルディング運動会コワーケーション",
    facility: "運動会",
    content:
      "企業・団体向け運動会では、あらゆる企業、労働組合、団体向けに、福利厚生やレクリエーションの場としての運動会・スポーツイベントの企画・運営を行なっています。お客様それぞれのニーズを検証し、社内のコミュニケーションの活性化やモチベーションの向上を図ることはもちろん、参加者一人ひとり、会社（団体）・チームなどのあり方を発見する機会となるような運動会を提供していきます。その貴重な時間はチームビルディングの場となり、組織に力を与え、会社を変える原動力ともなるでしょう。",
  },
  {
    image: "/contents/activity/2.png",
    title: "〜個人の「衝動」を組織に活かす〜冒険と対話の時間コワーケーション",
    facility: "つなラボ",
    content:
      "アドベンチャー・ダイアログは体験、対話、議論の3本柱からなっています。シャワークライミング体験後はランチを挟んで、たき火を囲みながら対話の時間。ゆらめくたき火を見つめていると心がやすらぎ、日頃なかなか話す機会のないメンバーの価値観や思いなどを伝えやすくなり、また相手の意見にも耳を傾けやすくなります。",
  },
  {
    image: "/contents/activity/3.png",
    title: "スポーツを通したコミュニケーション・ビジネス研修コワーケーション",
    facility: "軽井沢風越公園 軽井沢アイスパーク",
    content:
      "社員旅行や企業の運動会、各種クラブの余暇活動など、「レクリエーション」と「体験」を目的とした団体向けプログラムです。カーリングのゲームを重視し順位付けが出来るようにしています。楽しく盛り上がること間違いなし！仲間と心を合わせて行う動作が多いため、楽しく存分に体を動かしながら、コミュニケーションの活性も図ります。",
  },
  {
    image: "/contents/activity/4.png",
    title: "廃校の跡地を活用した”みんなで楽しむ”キャンプ場コワーケーション",
    facility: "CAMPiece MINAMI ASHIGARA",
    content:
      "コンセプトは”みんなで楽しむ” グラウンド跡地のテントサイトでは中央でレクリエーションやキャンプファイヤーをお楽しみ頂けます。社員旅行やワーケーション、合宿や運動会などでもご使用いただけます。南足柄の自然が満喫できる、廃校をノベーションしてできたキャンプ場です。同じ空間で協力しチームワークを自然と高めつつ、仲間との思い出をつくります。",
  },
  {
    image: "/contents/activity/5.png",
    title: "昔ながらの本格宿坊ステイ！写経や水行など体験修行も充実",
    facility: "お寺ステイ",
    content:
      "企業・団体向け運動会では、あらゆる企業、労働組合、団体向けに、福利厚生やレクリエーションの場としての運動会・スポーツイベントの企画・運営を行なっています。お客様それぞれのニーズを検証し、社内のコミュニケーションの活性化やモチベーションの向上を図ることはもちろん、参加者一人ひとり、会社（団体）・チームなどのあり方を発見する機会となるような運動会を提供していきます。その貴重な時間はチームビルディングの場となり、組織に力を与え、会社を変える原動力ともなるでしょう。",
  },
];
export { COACHING_DATA, ACTIVITY_DATA };
