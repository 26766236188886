export const STATIC_PLANS = [
  {
    value: "plan-a",
    planName: "A",
    images: [
      "/campaign/plans/1.png",
      "/campaign/plans/2.png",
      "/campaign/plans/3.png",
    ],
    tag: "日帰り｜銀座",
    category: "エグゼクティブ研修",
    persons: "8〜10名",
    title:
      "プロフェッショナルの仕事術セミナー3時間と事業ミーティングを銀座のナミキ スイートで",
    price: "44,734",
    group: 10,
    description:
      "銀座の街並みが一望できるハイアット セントリック 銀座 東京のナミキ スイート（127㎡）での事業ミーティングと　ライフバランスマネジメント研究所　講師：渡部卓氏によるプロフェッショナルの仕事術セミナーをはじめとする３時間の新社会人～役員層まで階層ごとの各種研修（研修内容のご希望に合わせてカスタマイズOK）が含まれた日帰り研修パッケージ。\nランチのほか終了後のプチ懇親会の飲食も含まれた、東京でのスタイリッシュな学びのひとときとオフサイトコミュニケーションにおすすめのコンテンツです。",
  },

  {
    value: "plan-b",
    planName: "B",
    images: [
      "/campaign/plans/4.png",
      "/campaign/plans/5.png",
      "/campaign/plans/6.png",
    ],
    tag: "1泊2日｜箱根",
    category: "エグゼクティブ研修",
    persons: "10〜20名",
    title:
      "シナジーを生み出すチームビルディング研修を箱根の合宿型コミュニケーション空間で",
    price: "50,270",
    group: 20,
    description:
      "ワンダーイマジニア　講師：今井千尋氏による世界２大テーマパークにて培ってきた人財育成メソッド  シナジーを生み出すチームビルディング研修（4時間）が含まれた企業合宿を箱根・仙石原の LIME RESORT HAKONEにて貸切で行う研修パッケージ。（講師スケジュールの関係上、実施日については要ご相談）\n夕朝食＋ランチが含まれ、チェックイン当日の正午12時～翌15時までの滞在が可能なほか、館内ラウンジやミーティングルームなどもご自由にカスタマイズいただけます。",
  },
  {
    value: "plan-c",
    planName: "C",
    images: [
      "/campaign/plans/7.png",
      "/campaign/plans/8.png",
      "/campaign/plans/9.png",
    ],
    tag: "1泊2日｜安曇野",
    category: "ウェルビーイング研修・合宿",
    persons: "10〜15名",
    title:
      "日常を止めずに非日常にひたる、自然の中にとことん心身を投じ五感を刺激するウェルビーイング研修合宿",
    price: "36,300",
    group: 10,
    description:
      "北アルプスを望む大自然に抱かれ、人里離れた山あいに佇むカミツレの里。\n土や木にふれ、大地の恵みを味わい、五感を通して自然の力を体いっぱいに感じながら生まれる発想やアイデアには、新しい発見や純粋さが宿ります。日本初のビオ（オーガニック）施設でいただくお食事（2食）や、薪割・かまど体験のアクティビティ、ここでしか体験できないカミツレエキス抽出工場見学、散策などが含まれます。この時代に求められる真のウェルビーイングをテーマに会議・研修・合宿で、ビジネスに不可欠な心の豊かさと強さを高めます。",
  },
  {
    value: "plan-d",
    planName: "D",
    images: [
      "/campaign/plans/10.png",
      "/campaign/plans/11.png",
      "/campaign/plans/12.png",
    ],
    tag: "2泊3日｜山梨",
    category: "おこもり合宿",
    persons: "10〜30名",
    title:
      "静寂の中で自分と見つめ合う時間、仲間と語り合う時間、宿坊でおこもり合宿。坐禅や瞑想、お寺でヨガ体験も。",
    price: "48,400",
    group: 10,
    description:
      "身延山は740年を超える歴史ある聖地。かつては僧侶や参拝客のための宿坊でしたが、最近は国内外の一般の観光客も宿泊することができる場所となり、企業や団体の合宿にも人気を集めています。\n年に一度は静寂の中で自分と仲間と見つめ合う時間をつくるお籠り合宿。精進料理の他、瞑想・写経・ヨガ体験など滞在中のアクティビティも豊富です。",
  },
  {
    value: "plan-e",
    planName: "E",
    images: [
      "/campaign/plans/13.png",
      "/campaign/plans/14.png",
      "/campaign/plans/15.png",
    ],
    tag: "2泊3日｜野沢温泉",
    category: "自然体験　チームビルディング研修",
    persons: "10名",
    title:
      "野沢温泉村の森・里山で仲間と過ごす自給自足ステイ。アグリツーリズムxチームビルディング研修。",
    price: "130,000",
    group: 10,
    description:
      "野沢温泉村のアクアツーリズムを体験できるLIFE FARMING CAMPで2泊3日のキャンププランです。テントで泊まり、オーガニックファームで採れた野菜を中心に自給自足の滞在を体験します。テント貼りから調理まで、チームボンディングが強くなること間違いなしです。テント４張を貸切10名から16名まで。気候の関係上、実施頂く時期は5月以降になります。",
  },
];
