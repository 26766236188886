export const FAQ = [
  {
    title: `コワーケーション.comへの申し込みや内容の依頼はどうすればいいですか？`,
    description: `お問い合わせフォームに入力・送信をお願いいたします。72時間以内に弊社より提案内容を連絡いたします。`,
  },
  {
    title: `問い合わせ・見積時点で費用は発生しますか？`,
    description: `発生いたしません。費用発生するのは、提案内容ご検討後、本申し込みをいただいてからとなります。`,
  },
  {
    title: `キャンセルポリシーについて教えてください。`,
    description: `詳細は、キャンセルポリシーをご確認ください。`,
  },
  {
    title: `合宿・企業研修のタイムテーブルや必要なものなどもお願いしたいのですが、可能ですか？`,
    description: `コワーケーション.comでは、タイムテーブル、必要備品、お食事手配の提案などもカスタマイズしていただくことが可能です。ホワイトボードなど会議用設備の有無についても施設ページに記載がございます。`,
  },
  {
    title: `合宿・企業研修中は自分も参加するので、その後の報告書など社内に提出するものが欲しい`,
    description: `コワーケーション.comでは合宿・企業研修終了後のアンケートのご記入をご参加者に頂くとその内容を集計レポートにして開催概要と合わせて7営業日以内にご報告・送付いたします。\n参加中が忙しくてまとめられなかった、タイムテーブルなどを覚えていないなど、合宿・企業研修後の困ったにも対応いたします。`,
  },
];
